@import '../../assets/css/_variables';

.wp-automation {
  position: relative;
  padding-top: 60px;

  &__error-generic {
    color: indianred;
    margin: 0 !important;
    font-size: 12px !important;
  }

  .auto-fix-modal img {
    margin: 0;
  }

  .formInput:first-of-type label {
    margin-bottom: 0;
  }

  .form-control {
    width: 100%;
    max-width: 100% !important;
  }

  &__logo {
    position: absolute;
    top: 0;
    margin-top: 0;
    left: -16px;
    max-width: 200px;
  }

  &__description {
    display: flex;
    font-size: 12px;
    margin-bottom: 0.5rem;
  }

  &__loading-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 320px;

    p {
      font-weight: 600;
    }
  }

  .btn-success {
    width: 100%;
    margin-top: 26px;
  }

  &--success {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .success__message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 36px 8px;
    border-radius: 4px;
    margin: 24px 0 48px 0;

    h3,
    p {
      margin: 0;
    }

    h3 {
      color: $soCoTertiary;
      margin-bottom: 8px;
    }
  }

  .success__breakdown {
    margin: 0 0 8px 0;
    font-size: 13px;
  }

  .plugins-breakdown {
    display: flex;
    margin-bottom: 24px;

    .plugin {
      list-style-type: none;
      margin: 0;
      padding: 6px 8px;
      background: #f8f9fa;
      border-radius: 4px;

      li {
        margin: 0;
      }

      &__name {
        font-weight: 600;
        font-size: 12px;
        color: #343a40;
      }

      &__status,
      &__action {
        font-size: 12px;
        line-height: 18px;
      }

      &__status,
      &__action {
        i {
          color: $soCoRed;
          font-weight: 600;
          margin-left: 4px;
        }
      }

      &__status--active,
      &__action--active {
        i {
          color: $soCoTertiary;
        }
      }
    }
  }

  .plugin:not(:first-child) {
    margin: 0 0 0 8px;
  }

  .update-wp__header {
    color: indianred;
  }
}

.go-divider {
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  margin: 2.3rem 0 2rem 0 !important;

  &__line {
    height: 1px;
    background-color: #888;
    width: 100%;
  }

  &__text {
    padding-right: 6px;
    padding-left: 6px;
    border-radius: 2px;
    font-size: 12px;
    border: 1px solid #888;
    color: #FFF;
    color: #888;
  }
}