@import '../../assets/css/_variables.scss';

.user-account {
  &__sub {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;

    &-duration {
      margin-top: 16px;
      font-size: 14px;
      font-weight: 600;
      text-align: center;

      @media (min-width: $mdBreak) {
        margin-top: 0;
      }
    }

    &-icon {
      width: 130px;
      height: 130px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      color: white;
      font-weight: bold;
      font-size: 22px;
      margin-bottom: 16px
    }

    .spinner-border {
      margin-left: 8px;
    }
  }

  &__manage-info {
    font-size: 12px;
    text-align: center;
    font-style: italic;
  }
}