.info-icon {
  color: #5f5f5f;
  font-size: 16px;
  height: 1em;
  vertical-align: middle;

  &.invert-color {
    color: white;
  }
}

.tooltip {
  &.show {
    opacity: 0.96;
  }

  .tooltip-inner {
    background-color: white;
    color: black;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.2);
    text-align: left;
    padding: 15px 20px;
    max-width: 250px;
  }

  .arrow::before {
    display: none;
  }
}
