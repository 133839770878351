@import '../../assets/css/_variables.scss';

.navigationAlt {
  display: flex;
  flex-direction: column;
  margin-top: 26px;

  &__site-selector {
    &-container {
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;
    }

    &-controls {
      display: flex;
      flex-direction: column;
    }
  }

  &__domain {
    color: $soCoPrimary;
    font-weight: 400;
    margin-right: 6px;

    &:hover {
      color: #1472c6;
    }

    &-info {
      display: flex;
      flex-direction: column;
      padding-left: 16px;

      @media (min-width: $smBreak) {
        flex-direction: row;
      }
    }

    &-uptime {
      color: grey;
    }
  }

  &__icon {
    position: relative;
    display: flex;
    padding: 12px;
    border: 1px solid #d8eafb;
    border-radius: 50%;

    i {
      font-size: 36px;
      color: $soCoPrimary;
    }

    &-status {
      position: absolute;
      bottom: 3px;
      right: -1px;
      box-sizing: border-box;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: 3px solid #f2f2f2;

      &--online {
        background-color: $soCoTertiary;
      }

      &--offline {
        background-color: $soCoRed;
      }
    }
  }

  .form-control {
    border: none;
    display: inline-flex;
    font-size: 1.5rem;
    font-weight: bold;
    padding-bottom: 0;
    padding-top: 0;
    height: auto;
    color: #5f5f5f;
    background-color: #f2f2f2;

    &:focus {
      outline: none;
    }
  }

  &__nav {
    ul {
      display: flex;
      list-style-type: none;
      margin: 12px 0 0 0;
      padding: 0;
    }

    a {
      font-weight: 400;
      color: grey;
      padding: .5rem 1rem;
      border-bottom: 2px solid #e0e0e0;

      &:hover {
        color: #161616;
      }
    }

    &-item--active  {
      > a {
        color: #161616;
        border-bottom: 2px solid $soCoPrimary;
      }
      
    }
  }
}