@import "../../assets/css/variables";

@mixin animation-wiggle() {
  @keyframes wiggle {
    0% { transform: rotate(-20deg); }
    13% { transform: rotate(20deg); }
    25% { transform: rotate(-20deg); }
    36% { transform: rotate(20deg); }
    46% { transform: rotate(-20deg); }
    55% { transform: rotate(20deg); }
    63% { transform: rotate(-20deg); }
    70% { transform: rotate(20deg); }
    76% { transform: rotate(-20deg); }
    81% { transform: rotate(20deg); }
    100% { transform: rotate(-200deg); }
  }
}
@include animation-wiggle;

.fa-hourglass {
  color: $soCoPrimary;
  margin-bottom: 1rem;
  animation: wiggle 8s ease infinite;
}
