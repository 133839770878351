@import '../../assets/css/_variables.scss';

.panel {
  background: white;
  padding: 0;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid $border-grey;

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 24px;
  }

  &__title {
    margin: 0;
    padding: 8px 0 8px 24px;
    font-size: 18px;
    font-weight: 700;
    border-bottom: 1px solid $border-grey;
  }
}