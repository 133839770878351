@import '../../assets/css/_variables.scss';

#root #stay-informed-alerts {
  .monitoring-switch {
    display: flex;
    flex: 0 0 100%;
    margin: 0 0 15px;
    min-width: 100%;
    flex-direction: column;
    justify-content: space-between;
    min-height: 400px;
    background-color: #ffffff;
    padding: 25px;
    text-align: left;
    box-shadow: rgba(1, 26, 98, 0.1) -1px 1px 1px 0px;

    @media (min-width: $mdBreak) {
      flex: 0 0 calc(50% - 10px);
      margin: 0 5px 15px;
      min-width: 250px;
    }

    @media (min-width: $lgBreak) {
      flex: 0 0 calc(25% - 23px);
      margin: 0 15px;
      min-width: 250px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .monitoring-switch-controls {
      text-align: right;
      height: 30px;
    }

    .monitoring-switch-image {
      margin: 25px auto 10px auto;
      width: 100%;
      text-align: center;
    }

    .monitoring-switch-info {
      margin-top: 15px;

      @media screen and (min-width: $lgBreak) {
        min-height: 198px;
      }

      @media screen and (min-width: $vlgBreak) {
        min-height: 174px;
      }

      .title {
        color: #5f5f5f;
        font-size: 18px;
        font-weight: bold;
      }

      .text {
        color: #6d6d6d;
        font-size: 16px;
      }
    }
  }

  /*                     Slider Button   
     - For SoConnect square use: label.switch.square && span.slider.square
     - For Original round with tick use: label.switch && span.slider.round
  */
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 24px;
    width: 24px;
    left: 6px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-repeat: no-repeat;
    background-position: center;
  }

  input:checked + .slider:before {
    background-image: url('data:image/jpeg;base64,/9j/4QAYRXhpZgAASUkqAAgAAAAAAAAAAAAAAP/sABFEdWNreQABAAQAAABQAAD/4QMraHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLwA8P3hwYWNrZXQgYmVnaW49Iu+7vyIgaWQ9Ilc1TTBNcENlaGlIenJlU3pOVGN6a2M5ZCI/PiA8eDp4bXBtZXRhIHhtbG5zOng9ImFkb2JlOm5zOm1ldGEvIiB4OnhtcHRrPSJBZG9iZSBYTVAgQ29yZSA1LjMtYzAxMSA2Ni4xNDU2NjEsIDIwMTIvMDIvMDYtMTQ6NTY6MjcgICAgICAgICI+IDxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+IDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCBDUzYgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkVCM0MyOTE5QUIyNjExRUE4RTQxQUIxODc4OUVGMDk0IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkVCM0MyOTFBQUIyNjExRUE4RTQxQUIxODc4OUVGMDk0Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RUIzQzI5MTdBQjI2MTFFQThFNDFBQjE4Nzg5RUYwOTQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RUIzQzI5MThBQjI2MTFFQThFNDFBQjE4Nzg5RUYwOTQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7/7gAOQWRvYmUAZMAAAAAB/9sAhAACAgICAgICAgICAwICAgMEAwICAwQFBAQEBAQFBgUFBQUFBQYGBwcIBwcGCQkKCgkJDAwMDAwMDAwMDAwMDAwMAQMDAwUEBQkGBgkNCwkLDQ8ODg4ODw8MDAwMDA8PDAwMDAwMDwwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAAMAA8DAREAAhEBAxEB/8QAdQABAQEAAAAAAAAAAAAAAAAABwgJAQEBAAAAAAAAAAAAAAAAAAADBBAAAAMGBAQHAAAAAAAAAAAAAQIDEhMEBQYHESIUFQAWCBghMWGCI2MXEQABAwIEBwEAAAAAAAAAAAACAAEhEQNRYaET8EGB4RIEFCL/2gAMAwEAAhEDEQA/ANN+86gacuVcy2t25XE2ljqGfxdPzWbKvoefyxEDGLFQjCZRBRUpWk0QbE4ZSiJwMQK/jIgYgmuil+oWJxKKaoYt/du8V2qlqXqim0VOLfdMtqJNOphTdCQ7IRdUpQUIsdZaITMYE1MGGgMIsEOBU0hEQUV4a5aC2zWmk3pOCILhm73HgW5Ypd6ue1Bmge5HT63cy8sOH+uctA/faT5dHjg9ay44M5uC9Tenb47pfZ2o3FUwcmcl5dn/AD3ZR8nG0bPp/H6NO49jHpxL+vLOvWqoimS//9k=');
  }

  input:checked + .slider {
    background-color: #5f5f5f;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 30px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .site-alerts {
    color: #5f5f5f;
    font-size: 26px;
    font-weight: bold;
    line-height: 24px;
    margin: 40px 0 30px;
  }

  /* Square slider css */
  .switch.square {
    width: 40px;
    height: 20px;
  }

  .slider {
    -webkit-transition: 0.2s;
    transition: 0.2s;
  }

  .slider.square:before {
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  input:checked + .slider.square:before {
    background-image: none;
  }

  input:checked + .slider.square {
    background-color: $soCoPrimary;
  }

  input:checked + .slider.square:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
}
