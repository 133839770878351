@import '../../assets/css/_variables';

.audit-passed {
  $size: 24px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: $size;
  color: $soCoRed;

  &-icon {
    margin-right: 10px;
    width: $size;
    height: $size;
  }

  &-passed {
    color: $soCoTertiary;
  }

  &-units {
    margin-left: 2px;
    font-size: 20px;
  }
}
