@import '../../assets/css/_variables.scss';

#root #audit-summary {

  .row-no-margin {
    margin: 0;
  }

  .radial-chart-container {
    @media (min-width: $smBreak) {
      margin-top: -26px;
    }
  }

  .chart-central .apexcharts-canvas {
    margin: 0 auto;
  }

  .chart-radius .bar {
    border-radius: 3px;
  }

  .audit-group-container {
    margin: 40px 0;
    padding: 30px;

    @media (min-width: $smBreak) {
      margin-top: 0;
      padding-left: 0;
    }
  }

  .audit-bar-chart-container {
    margin-bottom: 15px;
    min-height: 35px;

    svg {
      vertical-align: top;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .audit-bar-chart-title {
      font-weight: bold;

      .audit-bar-chart-score,
      .audit-bar-chart-score-seo {
        margin-left: 6px;
      }
    }
  }

  .audit_summary__domain {
    display: flex;
    flex: 1;
    justify-content: center;
    margin-top: 12px;
    p {
      font-size: 16px;
      line-height: 32px;
      font-weight: bold;
      margin: 0;
    }
  }

  .audit-summary-content {
    flex-direction: row !important;
  }
}
