@import '_variables';

.bold {
  font-weight: bold;
}

.light {
  font-weight: 300;
}

.scrollable-x {
  overflow-x: scroll;
}

.scrollable-y {
  overflow-y: scroll;
}

.flex {
  display: flex;
  flex-wrap: nowrap;
}

.fixedPosition {
  position: fixed;
  overflow: hidden;
  /* Prevent massive width issue */
  max-width: 100%;
}

select.form-control.rounded-border {
  border-radius: 900px;
}

select.custom {
  /* styling */
  background-color: white;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3em 0.5em 1em;
  font-size: 12px;
  font-weight: normal;

  /* reset */
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  &::-ms-expand {
    display: none;
  }

  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;

  &:focus {
    background-image: linear-gradient(45deg, gray 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, gray 50%);
    background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
      calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    border-color: gray;
    outline: 0;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
}

.small {
  font-size: 12px;
  font-weight: 300;
}

.v-a--top {
  vertical-align: top;
}

.v-a--bottom {
  vertical-align: bottom;
}

.m-t--0 {
  margin-top: 0 !important;
}

.m-t--10 {
  margin-top: 10px;
}

.m-t--15 {
  margin-top: 15px;
}

.m-t--20 {
  margin-top: 20px;
}

.m-t--30 {
  margin-top: 30px;
}

.m-t--45 {
  margin-top: 45px;
}

.m-b--0 {
  margin-bottom: 0;
}

.m-b--5 {
  margin-bottom: 5px;
}

.m-b--10 {
  margin-bottom: 10px;
}

.m-b--15 {
  margin-bottom: 15px;
}

.m-b--20 {
  margin-bottom: 20px;
}

.m-b--30 {
  margin-bottom: 30px;
}

.m-b--45 {
  margin-bottom: 45px;
}

.m-l--5 {
  margin-left: 5px;
}

.m-l--10 {
  margin-left: 10px;
}

.m-l--15 {
  margin-left: 15px;
}

.m-l--20 {
  margin-left: 20px;
}

.m-l--30 {
  margin-left: 30px;
}

.m-l--45 {
  margin-left: 45px;
}

.m-r--5 {
  margin-right: 5px;
}

.m-r--10 {
  margin-right: 10px;
}

.m-r--15 {
  margin-right: 15px;
}

.m-r--20 {
  margin-right: 20px;
}

.m-r--30 {
  margin-right: 30px;
}

.m-r--45 {
  margin-right: 45px;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.fal {
  &.green {
    color: $soCoTertiary;
  }

  &.orange {
    color: $orange;
  }

  &.red {
    color: $soCoSecondary;
  }
}

.green {
  color: $soCoTertiary;
}

.orange {
  color: $orange;
}

.red {
  color: $soCoSecondary;
}

.black {
  color: $black;
}

.grey {
  color: $grey;
}

.pointer {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  color: $black;
}
