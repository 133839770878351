@import '../../assets/css/_variables';

#backlinksPreview {
  .controls {
    color: #5f5f5f;
    font-weight: bold;
    font-size: 16px;
    margin-top: 10px;
    width: 100%;
  }

  .pointer {
    color: $soCoPrimary;
  }
}
