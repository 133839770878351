@import '../../assets/css/_variables.scss';

.toast {
  display: flex;
  color: white;
  margin-bottom: 30px;
  

  &.toast-danger {
    background-color: $soCoSecondary;
  }

  &.toast-info {
    background-color: $soCoPrimary;
  }

  .toast-details {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    font-size: 0.825rem;

    .toast-icon {
      font-size: 22px;
      font-weight: 700;
      padding-right: 1rem;
    }
  
    .toast-text {
      padding: 14px 0rem;
  
      .title {
        font-weight: 700;
        padding-right: 3px;
      }
    }
  }
  

  &-close {
    font-size: 17px;
    text-align: center;
    min-width: 3rem;
    min-height: 3rem;
    cursor: pointer;
    color: white;
    align-self: flex-start;
    justify-self: flex-end;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

