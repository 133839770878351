@import '../../assets/css/_variables.scss';

.add-site {
  // 100vh - header height
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .small-form-container {
    background-color: white;
    padding: 24px 16px;
    border: 1px solid $border-grey;
    border-radius: 2px;

    width: 400px;
    max-width: 100%;
  }

  .formFooter {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;

    .btn {
      width: 48%;
    }
  }

  &__status {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    margin-top: 16px;
    color: $black;

    p:last-child {
      margin-bottom: 0;
    }

    &--online {
      color: $soCoTertiary;
    }

    &--offline,
    &--error {
      color: $soCoSecondary;
    }

    &--loading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 0;
      min-height: 200px;

      .loading-circle {
        margin-bottom: 16px;
      }
    }
  }

  &__description {
    text-align: center;
    margin-bottom: 24px;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }
}