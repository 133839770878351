.noScreenshot {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__icon {
    color: #b3b3b3;
  }

  &__text {
    margin: 0;
    color: #a6a6a6;
    text-align: center;

    &--portrait {
      margin-top: 8px;
    }
  }
}
