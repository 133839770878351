.table {
  display: flex;
  flex-flow: column nowrap;
  font-size: 0.8rem;
  margin: 0.5rem;
  line-height: 1.5;
  border-bottom: 1px solid #d0d0d0;
  flex: 1 1 auto;
}

.th {
  display: none;
  font-weight: 700;
  background-color: #f2f2f2;
}

.th > .td {
  white-space: normal;
}

.tr {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
}

.tr:nth-of-type(even) {
  background-color: #f2f2f2;
}

.tr:nth-of-type(odd) {
  background-color: #ffffff;
}

.tr:last-child .td {
  border-bottom: none;
}

.td {
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  flex-basis: 0;
  padding: 0.5em;
  word-break: break-word;
  overflow: hidden;
  min-width: 0px;
  white-space: nowrap;
  border-bottom: 1px solid #d0d0d0;

  &:not(:first-child) {
    text-align: center;
    justify-content: center;
  }
}

.td > .info-icon {
  display: flex;
  padding: 1px 0 0 4px;
}

.flex-2 {
  flex-grow: 2;
}
