#theme-preview {
  display: flex;
  flex: 0 0 100%;
  flex-wrap: nowrap;

  .theme-preview-sidebar {
    flex: 0 0 25%;
    border: 1px solid #dedede;
    padding: 15px;
  }

  .theme-preview-inner {
    flex: 1 1 75%;
    border: 1px solid #dedede;
    padding: 15px;
  }
}