@import '../../assets/css/_variables.scss';

#admin-clients {
  .site-status-icon {
    font-size: 18px;

    &.green {
      color: $soCoTertiary;
    }

    &.red {
      color: $soCoSecondary;
    }
  }
}
