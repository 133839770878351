#site-unavailable {
  .standard-box-content {
    min-height: 0;
  }
}

// 100vh -30px margin
.height-100vh {
  height: calc(100vh - 30px);
}

// 100vh -30px margin && -100px footer when user is a selfSignUp
.height-100vh-minus-footer {
  height: calc(100vh - 130px);
} 