@import '../../assets/css/_variables.scss';

.user-account {
  display: flex;
  // 100vh - header height - footer height
  min-height: calc(100vh - 200px);
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__container {
    display: flex;
    flex-direction: column;
    width: 90%;

    @media (min-width: $mdBreak) {
      flex-direction: row;
    }
  }

  &__plan,
  &__details {
    flex: 1;
    margin-bottom: 16px;

    @media (min-width: $mdBreak) {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }

  &__details {
    min-height: 376px;
    flex: 2;
  }
}