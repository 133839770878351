@import '../../assets/css/_variables';

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: initial;
  border: 1px solid rgba(0,0,0,.05);
  border-radius: .375rem;
  margin-bottom: 24px;
  box-shadow: 0 0 2rem 0 rgba(136,152,170,.15);

  .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.5rem;

    .card-title-value {
      font-size: 18px;

      @media (min-width: $lgBreak) {
        font-size: 24px;
        line-height: 34px;
        min-height: 38px;
        display: block;
      }

      @media (min-width: $vlgBreak) {
        font-size: 28px;
        line-height: 38px;
      }
    }

    .col-right {
      padding: 0 10px 0 4px;
    }
  }

  .card-icon {
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 12px;
    width: 3rem;
    height: 3rem;
    background-color: white;
  }
}