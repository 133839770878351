@import '../../assets/css/_variables.scss';

.top-issues {
  .standard-box-content {
    background-color: #ffffff;
    min-height: 300px;
    padding: 30px;
    flex-grow: 1;
    text-align: left;

    @media (min-width: $mdBreak) {
      min-height: 320px;
    }

    &.standard-box-content-thin {
      padding: 30px 20px;
    }

    &.standard-box-content-no-padding {
      padding: 0;
    }

    &.standard-box-content-no-border {
      border: none;
    }

    &.standard-box-auto-height {
      @media (min-width: $mdBreak) {
        min-height: 0;
      }
    }
  }

  .audit-section-show-pages {
    font-weight: 700;
    cursor: pointer;
    user-select: none;
    color: $brand-color;

    &-chevron {
      margin-right: 20px;
    }
  }
}