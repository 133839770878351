@import '../../assets/css/_variables.scss';

.request-help {
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  &__error-generic {
    color: indianred;
    margin: 0;
    font-size: 12px;
  }

  &__description {
    margin-bottom: 32px
  }

  .form-control {
    width: 100%;
    max-width: 100% !important;
  }

  &__header {
    display: flex;
    flex-direction: column;
    margin: 0 0 18px 0;
    padding: 6px 8px;
    font-size: 14px;
    list-style-type: none;
    background-color: lighten($grey, 15%);
    background-color: #f7f7f7;
    border-radius: 4px;
    span {
      color: #333;
      font-weight: 700;
    }
  }

  &__success-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 426px;
  }

  .btn-success {
    width: 100%;
    margin-top: 26px;
  }

  .form-asterisk {
    display: flex;
    font-size: 12px;
    margin-top: 32px;
    font-style: italic;

    .input-required {
      margin-right: 4px;
    }
  }
}