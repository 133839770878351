.subscription-invoices {
  display: flex;
  flex-direction: column;
  flex: 1;

  &--empty {
    justify-content: center;
    align-items: center;
  }

  &__invoices {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    max-height: 290px;
  }

  li {
    padding: 4px 0;
  }
}