@import '../../assets/css/_variables.scss';

#register-agency {
  background-color: white;
  min-height: 100vh;

  @media (min-width: $smBreak) {
    padding: 0 !important;
  }

  // Sign up completed stage only
  &.complete {
    @media (min-width: $lgBreak) {
      background-image: url('https://gorank-partner-files.s3-eu-west-1.amazonaws.com/build-website.png');
      background-size: 50%;
      background-position-x: right;
      background-repeat: no-repeat;
      background-position-y: bottom;
      display: flex;
      align-items: center;
    }

    .main-content-container {
      @media (min-width: $lgBreak) {
        padding-bottom: 25vh;
      }
    }
  }

  .gorank-logo {
    height: 40px;
    margin-bottom: 30px;

    @media (min-width: $smBreak) {
      margin-bottom: 45px;
    }
  }

  .main-content-container {
    margin: 0 auto;
    padding-top: 30px;
  }

  .small-form-container {
    margin: 40px auto;

    @media (min-width: $smBreak) {
      margin: 0 auto 15px;
    }

    width: 400px;
    max-width: 100%;
  }

  #loginMarketingTitle {
    font-size: 26px;
    line-height: 32px;
    color: white;
    margin-bottom: 45px;

    @media (min-width: $lgBreak) {
      font-size: 28px;
      line-height: 34px;
    }
  }

  .register-feature {
    color: white;
    display: flex;
    flex-wrap: nowrap;
    height: 30px;
    line-height: 30px;
    width: 250px;
    max-width: 100%;
    margin: 0 auto 15px;

    @media (min-width: $vlgBreak) {
      margin-bottom: 20px;
    }

    .feature-icon {
      flex: 0 0 40px;
      font-size: 24px;
    }

    .feature-title {
      flex: 0 0 calc(100% - 40px);
      font-size: 21px;
      vertical-align: middle;
      text-align: left;
      padding-left: 15px;
    }
  }

  .partner-confirmation {
    p {
      font-size: 16px;
    }

    .primary-title {
      font-size: 52px;
      line-height: 80px;
      margin-bottom: 30px;
    }

    .setup-img {
      max-width: 100%;
      width: 300px;
      margin: 0 15px 30px 30px;
    }

    .setup-img {
      max-width: 100%;
      width: 300px;

      @media (min-width: $smBreak) {
        padding-left: 30px;
        padding-bottom: 30px;
      }
    }

    .setup-wizard-img {
      max-width: 100%;
      width: 300px;

      @media (min-width: $smBreak) {
        padding-right: 10px;
        margin: 40px 15px 15px 0;
      }
    }
  }

  form {
    width: 400px;
    max-width: 100%;
    margin: 0 auto;
  }

  .full-height {
    min-height: 100vh;
  }
}

.subdomain-input {
  text-align: right;

  &.unavailable {
    border: 1px solid $soCoSecondary;
  }
}

#register-bg {
  height: 100vh;
  padding: 6%;
  background-color: #4c95cd;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (min-width: $lgBreak) {
    padding: 10%;
  }
}
