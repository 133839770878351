.empty-state-container {
  box-shadow: rgba(1, 26, 98, 0.1) -1px 1px 1px 0px;
  background-color: white;
  min-height: 200px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .icon-container {
    font-size: 48px;
    background-color: #eee;
    border-radius: 50%;
    padding: 5px;
    min-width: 80px;
    text-align: center;
    margin-bottom: 15px;
  }
}

