@import '../../assets/css/_variables.scss';

#register {
  background-color: white;
  min-height: 100vh;

  @media(min-width: $smBreak) {
    height: 100vh;
    padding: 0 !important;
  }

  .align-desktop {
    align-items: center;
  }

  .small-form-container {

    padding-top: 15px;
    margin: 0 auto;

    @media(min-width: $smBreak) {
      padding-top: 0;
    }

    width: 400px;
    max-width: 100%;
  }

  .register-feature {
    color: white;
    display: flex;
    flex-wrap: nowrap;
    height: 30px;
    line-height: 30px;
    width: 300px;
    max-width: 100%;
    margin-bottom: 12px;

    @media(min-width: $vlgBreak) {
      margin-bottom: 15px;
    }

    .feature-icon {
      flex: 0 0 40px;
      font-size: 24px;
    }

    .feature-title {
      flex: 0 0 calc(100% - 40px);
      font-size: 21px;
      vertical-align: middle;
      text-align: left;
      padding-left: 15px;
    }
  }

  #register-bg {
    height: 100vh;
    padding: 6%;
    background-color: #4c95cd;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media(min-width: $lgBreak) {
      padding: 10%;
    }
  }

  #loginMarketingTitle {
    font-size: 28px;
    line-height: 34px;
    color: white;
  }

  .img {
    width: 450px;
    max-width: 80%;
    margin-bottom: 45px;
  }
}