@import '../../assets/css/_variables.scss';

#root .audit-section {
  .show-more-button {
    display: flex;
    justify-content: flex-end;

    &-button {
      margin: 20px;
    }
  }

  .flex-end {
    align-self: flex-end;
  }

  .desktop-order-bump {
    @media screen and (min-width: $smBreak) {
      order: +1;
    }
  } 

  .audit-section {
    &-header {
      margin-bottom: 40px;
      padding: 3.5rem 2rem;

      @media screen and (min-width: $smBreak) {
        margin-bottom: 50px;
      }

      .audit-bar-chart-container {
        margin-left: auto;
        align-self: center;
      }
    }

    &-header-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-title {
      color: $brand-color;
      font-size: 26px;
      font-weight: bold;
      line-height: 32px;
      width: 100%;
      margin-top: 10px;
    }

    &-description {
      @media screen and (min-width: $smBreak) {
        margin-bottom: 0;
      }
    }

    &-box {
      @media screen and (max-width: $smBreak - 1) {
        + .audit-section-box {
          margin-top: 30px;
        }
      }

      &-title {
        color: $brand-color;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;

        &-icon {
          margin-left: 5px;
          margin-bottom: 10px;
        }
      }
    }

    &-screenshot {
      width: 100%;
      max-width: 195px;
      position: relative;

      &-wrapper {
        max-width: 190px;
      }

      &-image {
        position: absolute;
        max-width: 180px;
        top: 17px;
        left: 6px;
      }
    }

    &-show-pages {
      font-weight: 700;
      cursor: pointer;
      user-select: none;
      color: $brand-color;

      &-chevron {
        margin-right: 20px;
      }
    }

    &-no-data {
      font-style: italic;
    }
  }

  .no-margin {
    margin: 0;
  }
}
