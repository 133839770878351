@import '../../assets/css/_variables.scss';

#root #site-summary {
  .hide-mobile {
    @media screen and (max-width: $smBreak) {
      display: none;
    }
  }

  #siteSummaryContainer {
    #siteSummaryScreenshot {
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2);

      @media (min-width: $lgBreak) {
        margin-top: 20px;
      }

      @media (min-width: $vlgBreak) {
        margin-right: 20px;
        position: absolute;
        left: calc(50% - 385px);
      }

      canvas {
        transition: opacity 500ms ease-in-out;
      }
    }

    .flex-row {
      display: flex;
      align-items: center;
      flex: 1 1 100%;
    }

    .site-flow-chart {
      width: 50%;
      display: inline-block;
      margin-bottom: 30px;

      &.chart-no-margin {
        margin: 0;
      }
    }
  }

  .search-engine-label {
    padding: 0 15px;
    margin-bottom: 15px;
    position: relative;
    font-size: 18px;
    font-weight: 700;

    @media screen and (min-width: $vsmBreak) {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  .search-engine-title-item {
    display: flex;
    flex-direction: column;
    flex: 1 0 100%;
    padding: 5px 20px;

    @media (min-width: $smBreak) {
      width: 100%;
    }

    @media (min-width: $lgBreak) {
      flex: 1 0 20%;
    }
  }

  .search-engine-container {
    flex-direction: column;
    flex-wrap: wrap;

    @media (min-width: $smBreak) {
      flex-direction: row;
    }
  }

  .search-engine-logo-item {
    display: flex;
    justify-content: center;
    padding: 9px 9px 9px 15px;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 20px;

      @media (min-width: $smBreak) {
        margin-bottom: 0;
      }
    }

    @media (min-width: $smBreak) {
      flex: 1 0 20%;

      &:last-child {
        /* Needed for shadow to show */
        padding-right: 4px;
        padding-left: 14px;
      }
    }

    img {
      width: auto;
      height: auto;
      max-height: 60px;

      @media (min-width: $smBreak) {
        max-width: 100%;
      }
    }

    .search-engine-logo-item-container {
      $text-height: 1.5rem;
      $text-offset: 4 / 10;
      height: 92px;
      background-color: #ffffff;
      padding: 0 15%;
      display: flex;
      position: relative;
      max-width: 400px;
      width: 100%;
      border-radius: 4rem;
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2);

      @media (min-width: $smBreak) {
        min-width: 0;
      }

      .search-engine-logo-item-title {
        flex: 0 0 0;
        transform: rotate(-90deg);
        transform-origin: center;
        width: 100%;
        align-self: center;
        text-align: center;
        font-weight: bold;
        font-size: 19px;
        line-height: 30px;
      }

      .search-engine-logo-item-logo {
        flex: 1 0 50%;
        padding: calc(15px - #{$text-height * $text-offset / 2}) 0 15px;
        align-self: center;
      }

      @mixin label {
        position: absolute;
        bottom: 0;
        left: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translate(-50%, #{100% - percentage($text-offset)});
        color: #fff;
        border-radius: 4rem;
        height: $text-height;
        padding: 0 0.5rem;
        font-size: 0.8rem;
        min-width: 160px;

        > svg {
          width: 1rem;
          height: 1rem;
          margin-right: 0.25rem;
        }

        @media (min-width: $smBreak) {
          font-size: 0.6rem;
          min-width: 140px;
        }

        @media (min-width: $mdBreak) {
          font-size: 0.7rem;
          min-width: 160px;
        }

        @media (min-width: $lgBreak) {
          font-size: 0.8rem;
        }
      }

      .search-engine-logo-item-indexed {
        @include label;
        background-color: $soCoTertiary;

        > svg > path {
          fill: #fff;
        }
      }

      .search-engine-logo-item-pending {
        @include label;
        background-color: $orange;
      }
    }
  }

  .overflow-x {
    overflow-x: visible;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .search-engine-title-item {
    flex-wrap: wrap;
    text-align: center;
    align-self: flex-start;
    margin-bottom: 20px;
    width: 100%;

    @media (min-width: $smBreak) {
      align-self: center;
      width: auto;
    }

    @media (min-width: $lgBreak) {
      margin-bottom: 0;
    }

    .search-engine-title-item-subheader {
      font-size: 16px;
      line-height: 22px;
      color: #19535f;

      @media (min-width: $smBreak) {
        font-size: 24px;
        line-height: 36px;
      }

      .search-engine-title-item-subheader-number {
        font-size: 40px;
        line-height: 40px;
        width: 100%;
      }
    }

    .search-engine-title-item-text {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      line-height: 22px;
      color: #19535f;
      font-weight: bold;

      @media (min-width: $smBreak) {
        font-size: 24px;
        line-height: 24px;
      }
    }
  }

  .dashboard-box .dashboard-box-content.competitor-table,
  .dashboard-box .dashboard-box-content.savings-table {
    padding: 0;

    .competitor-table-row-highlight.bg-gradient-primary {
      .competitor-item-name {
        color: #fff;
      }

      @for $i from 1 through 5 {
        .competitor-item-number-content.competitor-item-number-content-#{$i} {
          background-color: #fff;
          color: inherit;
        }
      }
    }

    .dashboard-table-row {
      display: flex;
      flex-wrap: nowrap;
      flex: 0 0 100%;
      justify-content: flex-start;
      padding: 15px 30px;
      height: 80px;
      align-items: center;
      text-align: left;
      border-bottom: 1px solid #5656561c;

      &:last-child {
        border-bottom: none;
      }
    }

    .savings-table-row {
      @extend .dashboard-table-row;

      .savings-name {
        flex: 1 0 50%;
        font-size: 16px;
        letter-spacing: -0.46px;
        line-height: 24px;
        color: #5f5f5f;
      }

      .savings-number {
        flex: 0 0 80px;
      }
    }

    .competitor-table-row {
      @extend .dashboard-table-row;

      .competitor-item-number {
        flex: 0 0 30px;

        .competitor-item-number-content-1 {
          background-color: #fbd56f;
          color: #926a00;
        }

        .competitor-item-number-content-2 {
          background-color: #cfd6d7;
          color: #50595a;
        }

        .competitor-item-number-content-3 {
          background-color: #b56a066e;
          color: #d26c10;
        }

        .competitor-item-number-content-4 {
          background-color: #ff75756e;
          color: #d63130;
        }

        .competitor-item-number-content-5 {
          background-color: #ff78a96e;
          color: #e84293;
        }

        .competitor-item-number-content {
          width: 40px;
          height: 40px;
          border-radius: 40px;
          padding: 10px;
          font-weight: bold;
          text-align: center;
        }
      }

      .competitor-item-icon {
        flex: 0 0 60px;

        img {
          width: 40px;
        }
      }

      .competitor-item-name {
        flex: 1 0 50%;
        font-size: 16px;
        letter-spacing: -0.46px;
        line-height: 24px;
        color: #5f5f5f;
      }
    }
  }

  .dashboard-box .dashboard-box-content.savings-table {
    img {
      padding: 30px;
    }
  }

  .dashboard-hr {
    box-sizing: border-box;
    height: 1px;
    width: 100%;
    border-bottom: 1px solid #243d57;
    opacity: 0.25;
    margin: 30px 0;
  }

  .dashboard-box {
    margin-bottom: 30px;
    flex-direction: column;
    display: flex;

    &__row {
      flex-direction: column;

      @media (min-width: $mdBreak) {
        flex-direction: row;
      }
    }

    @media (min-width: $mdBreak) {
      margin-bottom: 48px;

      &:nth-child(3),
      &:nth-child(4),
      &--uptime {
        margin-bottom: 0;
      }
    }

    .dashboard-box-header {
      display: flex;
      align-items: center;
      min-height: 45px;
      font-size: 16px;
      font-weight: 700;
      padding: 17.5px;
      border-bottom: none;
      box-shadow: none;

      &__bg-grey {
        background-color: #e0e0e0;
        color: #5f5f5f;
      }

      &--keywords,
      &--savings,
      &--competitors,
      &--backlinks {
        display: flex;
        flex-direction: column;
        @media (min-width: $smBreak) {
          display: flex;
          flex-direction: row;
        }
      }

      @media (min-width: $mdBreak) {
        min-height: 60px;
        font-size: 18px;
      }

      &__wrapper {
        select.currency-change {
          margin-right: 16px;
        }

        &:first-child {
          padding: 0 15px 0 15px;
        }

        &:last-child {
          margin-top: 16px;

          @media (min-width: $smBreak) {
            margin-top: 0;
          }
        }

        &-savings {
          @media (min-width: $mdBreak) {
            max-width: 50%;
          }

          @media (min-width: 1250px) {
            max-width: 100%;
          }
        }
      }
    }

    .dashboard-box-content {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      background-color: transparent;
      min-height: 300px;
      padding: 30px;

      @media (min-width: $mdBreak) {
        min-height: 320px;
      }

      &-no-min-height {
        min-height: 0;
      }

      &.dashboard-box-content-thin {
        padding: 30px 20px;
      }
    }
  }

  .backlink-screenshot-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .backlink-screenshot {
      flex: 0 0 100%;
      margin-bottom: 15px;

      @media (min-width: 680px) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      img {
        max-width: 90%;
        min-width: 150px;
        min-height: 150px;

        @media (min-width: $lgBreak) {
          min-width: 175px;
          min-height: 175px;
        }

        @media (min-width: $vlgBreak) {
          min-width: 200px;
        }
      }
    }
  }

  .savings-padding {
    padding-bottom: 10px;

    @media (min-width: $smBreak) {
      padding-bottom: 20px;
    }
  }

  .savings-info {
    width: 350px;
    margin: 0 auto;
    color: #5f5f5f;
    padding-bottom: 20px;

    .savings-amount {
      font-size: 42px;
      text-align: right;

      @media (min-width: $smBreak) {
        font-size: 50px;
      }
    }

    .savings-amount-info {
      font-size: 18px;
      line-height: 20px;
      padding-left: 10px;
      text-align: left;
      border-left: 1px solid #243d57;
      padding-right: 0;

      @media (min-width: $smBreak) {
        font-size: 24px;
        line-height: 24px;
        padding-left: 20px;
      }
    }

    .savings-amount-green {
      color: $soCoTertiary;
    }
  }

  .latest-ranking-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;

    @media (min-width: $mdBreak) {
      flex-wrap: nowrap;
    }

    .latest-ranking-score {
      padding: 5px;
      flex: 1 1 100%;
      text-align: center;
      margin-bottom: 10px;

      @media (min-width: $mdBreak) {
        padding: 15px;
        margin-bottom: 0;
      }

      .latest-ranking-bubble {
        padding: 10px;
        color: white;
        border-radius: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 80px;
        max-width: 80px;
        margin: 0 auto;
        background-color: #dadada;

        @media (min-width: $smBreak) {
          height: 110px;
          max-width: 110px;
        }

        @media (min-width: $lgBreak) {
          height: 140px;
          max-width: 140px;
        }

        .latest-ranking-bubble-position {
          font-weight: bold;
          font-size: 34px;
          line-height: 30px;

          @media (min-width: $lgBreak) {
            font-size: 60px;
            line-height: 50px;
          }
        }

        .latest-ranking-bubble-rank-label {
          margin-top: 5px;
          font-size: 12px;

          @media (min-width: $lgBreak) {
            font-size: 16px;
          }
        }
      }

      .latest-ranking-keyword-label {
        font-weight: bold;
        font-size: 14px;
        margin-top: 15px;

        @media (min-width: $lgBreak) {
          font-size: 18px;
        }
      }
    }
  }

  .latest-ranking-info {
    display: flex;
    text-align: left;
    flex-direction: column;
    justify-content: flex-end;

    @media (min-width: $mdBreak) {
      min-height: 160px;
    }
  }

  .no-results {
    height: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .small-question {
    font-weight: bold;
    font-size: 30px;
    line-height: 30px;

    @media (min-width: $lgBreak) {
      font-size: 40px;
      line-height: 50px;
    }
  }
}

/* Modal styling, do not nest */
.edit-keywords-container {
  .form-control {
    border: none;
    display: inline-flex;
    font-weight: bold;
    padding-bottom: 0;
    padding-top: 0;
    height: auto;
    color: #5f5f5f;

    &:focus {
      outline: none;
    }
  }
}