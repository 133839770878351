$black: #6f6f6f;
$grey: #cdcdcd;
$blue: #1e5896;
$green: #0d975e;
$orange: #f59e4d;
$red: #b93d3b;
$blue-green: #19535F;
$brand-color: #5f5f5f;
$border-grey: rgba(205, 205, 205, 0.459);

// SoConnect colours
// 1. Theme
$soCoPrimary: #3c98eb;
$soCoSecondary: #ff783c;
$soCoTertiary: #3cba54;
$soCoRed: #ea4335;

// 2. Greys
$soCoGray100: #f9f9f9;
$soCoGray150: #f7f7f7;
$soCoGray200: #f2f2f2;
$soCoGray450: #c1c7cd;
$soCoGray500: #b2b2b2;
$soCoGray550: gray;
$soCoGray750: #3f3f3f;

// 3. Alert Colors
$soCoSuccess: #d8eacc;
$soCoWarning: #fbeeca;
$soCoDanger: #fbcfbd;

// 4. Graph Colors
$soCoGraph1: #af4bce;
$soCoGraph2: #1092e6;
$soCoGraph3: #eabd3b;
$soCoGraph4: #5ed1d1;

$vsmBreak: 450px;
$smBreak: 768px;
$mdBreak: 992px;
$lgBreak: 1200px;
$vlgBreak: 1500px;

$h1Size: 30px;
$h2Size: 24px;
$h3Size: 21px;
$h4Size: 19px;
