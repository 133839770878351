.admin-setup-webhooks {
  margin-bottom: 30px;
  border-left: 1px solid #eee;
  padding: 12px;
  background-color: darken(white, 2%);

  &__title {
    text-decoration: underline;
  }

  &__error {
    margin-top: 16px;
    margin-bottom: 0;
    color: indianred;
  }

  .webhooks-items {
    &__wrapper {
      display: flex;
      flex: 1;
      margin-top: 18px;
      padding-top: 18px;
      border-top: 1px solid #eee;
    }

    list-style-type: none;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0;
    padding: 0 24px 0 0;
  }

  .webhook-item {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 12px;

    &__name {
      margin-bottom: 0;
    }

    &__description {
      margin-top: 4px;
      font-size: 12px;
    }
  }
}