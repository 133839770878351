@import '../../assets/css/_variables.scss';

.issue-item {
  $padding: 30px;
  display: flex;
  border-bottom: 1px solid #243d5726;

  &:last-child {
    border-bottom: none;
  }

  @mixin passFail {
    display: block;
    color: white;
    font-size: 11px;
    line-height: 20px;
    height: 28px;
    width: 70px;
    padding: 4px 10px 4px 7px;
    text-align: center;
    // Cannot use absolute positioning on flex item for IE11
    margin-left: -36px;
    // Cannot use absolute positioning on flex item for IE11
    margin-right: -36px;
    border-radius: 5px 20px 20px 5px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
  }

  &-pass {
    @include passFail;
    background-color: $soCoTertiary;
  }

  &-fail {
    @include passFail;
    background-color: $soCoRed;
  }

  .issue-item-locator-table {
    table-layout: fixed;
    width: 100%;

    td,
    th {
      padding: 10px;
      border: 1px solid $grey;
    }

    a {
      // Force links full width for UX
      display: block;
      word-break: break-all;
    }

    p,
    li {
      &:only-child {
        margin: 0;
      }
    }
  }

  .issue-item-inner {
    // Flex column required for dynamic vertical height with vertical alignment in IE11
    display: flex;
    // Flex column required for dynamic vertical height with vertical alignment in IE11
    flex-direction: column;
    width: 100%;
  }

  .issue-item-difficulty {
    margin-right: 30px;
    font-weight: bold;

    .difficulty-score {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      color: $soCoTertiary;
      border: 1px solid;
      font-size: 11px;
      line-height: 20px;
      height: 28px;
      width: 70px;
      padding: 4px 10px;

      &.difficulty-medium {
        color: $orange;
      }

      &.difficulty-difficult {
        color: $soCoSecondary;
      }
    }
  }

  .issue-item-last-scan {
    margin: 0 30px 0 0;
  }

  .issue-item-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 20px 0;
  }

  .issue-item-header {
    min-height: 70px;
    display: flex;
    position: relative;
    align-items: center;
    cursor: pointer;
    margin: 0 $padding;
    user-select: none;

    .issue-item-header-title {
      flex: 1 0 50%;
      color: #5f5f5f;
      font-weight: bold;
      font-size: 16px;
      margin: 10px 0 10px 60px;

      @media (min-width: $lgBreak) {
        font-size: 18px;
      }
    }

    .issue-item-header-toggle {
      flex: 0 1 60px;
      font-size: 21px;
      justify-content: flex-end;
      display: flex;
      padding-right: 10px;
    }
  }

  .issue-item-info {
    margin: 0 $padding;
    padding-bottom: 10px;
  }

  .issue-item-details {
    display: flex;
    margin-bottom: 18px;
  }

  .issue-item-action {
    font-weight: normal;
    font-size: 12px;
    cursor: pointer;
    border-radius: 5px;
    padding: 6px 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: $soCoPrimary;

    &:hover {
      background-color: #091e22;
    }

    &:not(:last-child) {
      margin-right: 18px;
    }
  }
}