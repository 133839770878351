.dns-status-icon {
  margin-right: 5px;
}

.img-preview {
  max-height: 65px;
  max-width: 100%;
  background-color: #f6f6f6;
}

.img-missing {
  font-size: 45px;
  padding: 5px;
}