@import '../../assets/css/_variables.scss';

.uptime_status {
    font-weight: bold;
    text-transform: uppercase;

    &--up {
        color: $soCoTertiary;
    }

    &--down {
        color: $soCoRed;
    }
}

.uptime_filters {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 0 0.5rem;
}

.uptime_filter {
  display: flex;
  align-items: center;
  margin-right: 1rem;

  @media (min-width: $mdBreak) {
    margin-right: 2rem;
  }

  button {
    text-transform: capitalize;
    color: #19535f;
    font-weight: bold;
    background: transparent;
    border: none;
    padding-left: 0;
  }
}

.dashboard-box--uptime {
    .controls {
        color: #5f5f5f;
        font-weight: bold;
        font-size: 16px;
        margin-top: 22px;
        width: 100%;
    }

    .pointer {
      color: $soCoPrimary;
    }
}

.uptime-table {
  &--no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 349px;
    margin: 0;
  }
}