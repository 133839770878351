@import '../../assets/css/_variables.scss';

.ranking-chart {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__keyword,
  &__keyword-diff,
  &__last-update {
    margin: 0;
  }

  &__last-update {
    font-size: 14px;
    font-weight: 700;
    text-align: right;
    color: #333;
    margin-bottom: 16px;

    @media (min-width: $smBreak) {
      margin-bottom: 0;
    }
  }

  &__scores-list {
    display: flex;
    flex-direction: column;
    flex: 1;
    list-style-type: none;
    margin: 0;
    padding: 0;
    justify-content: stretch;
  }

  &__score-item {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 32px 0;
    align-items: center;

    @media (min-width: $smBreak) {
      flex-direction: row;
      padding: 8px 0;
    }

    &--unknown {
      flex-direction: column;
      justify-content: center;
      color: #888;
      background: #f7f7f7;

      p {
        margin: 0;
      }

      strong {
        color: #666;
        text-decoration: underline;
      }
    }
  }

  &__score-item:not(:last-child) {
    border-bottom: 1px solid #eee;
  }

  &__keyword {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 4px;
  }

  &__keyword-diff {
    font-size: 14px;
  }

  &__last-score {
    flex: 1;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 84px;
      width: 84px;
      border: 4px solid tomato;
      border-radius: 50%;

      font-size: 26px;
      color: #333;
    }

    &--blue {
      span {
        border-color: $soCoGraph2;
      } 
    }

    &--turquoise {
      span {
        border-color: $soCoGraph4;
      }
    }

    &--purple {
      span {
        border-color: $soCoGraph1;
      }
    }
  }

  &__keyword-info {
    display: flex;
    flex-direction: column;
    flex: 2;
    align-items: center;
    justify-content: center;
    color: #333;
    margin: 8px 0 24px 0;

    @media (min-width: $smBreak) {
      align-items: flex-start;
      margin: 0;
    }
  }

  &__graph-cta {
    display: flex;
    flex: 1;
    justify-content: flex-end;

    button {
      background: transparent;
      border: 1px solid #333;
      color: #333;
      border-radius: 2px;
      padding: 8px 12px;

      &:hover {
        background: #333;
        color: #eee;
      }
    }
  }
}
