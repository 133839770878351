@import '../../assets/css/_variables.scss';

#mobileMenuOpenContainer {
  background-color: #5f5f5f;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 30px;
  color: #ffffff;
  font-size: 18px;
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .mobile-menu-controls {
    display: flex;
    flex: 0 0 60px;
    align-items: flex-start;
    justify-content: flex-end;
    font-size: 24px;
    font-weight: bold;
  }

  .mobile-menu-links {
    display: flex;
    flex-direction: column;
    flex: 1 0 50%;
    justify-content: center;

    .mobile-menu-link {
      display: block;
      width: 100%;
      margin-bottom: 30px;

      i {
        color: #ffffff;
      }

      .mobile-menu-link-text {
        padding-bottom: 3px;
        border-bottom: 2px solid transparent;

        a {
          color: #ffffff;
        }

        &.active {
          font-weight: bold;
          border-bottom: 2px solid $soCoPrimary;
        }
      }
    }
  }

  .mobile-menu-terms {
    display: flex;
    flex: 0 0 200px;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 30px;

    .mobile-menu-term-text {
      display: block;
      width: 100%;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

#headerAlt {
  width: 100%;
  transition: all 0.2s ease-in, margin 0s;
  top: 0;
  background-color: #f2f2f2;
  z-index: 5000;
  padding-top: 10px;
  padding-bottom: 7px;
  border-bottom: 2px solid #e0e0e0;
}

#header {
  width: 100%;
  transition: all 0.2s ease-in, margin 0s;
  top: 0;
  background-color: #fff;
  z-index: 5000;
  height: 60px;
  padding-top: 10px;

  body.iframeMode & {
    .organisation-logo {
      display: none !important;
    }

    #languageSelectContainer {
      display: none !important;
    }
  }

  @media (max-width: $smBreak) {
    #app & {
      .container-fluid {
        padding: 10px 15px 5px 15px;
      }
    }
  }

  @media (min-width: $smBreak) {
    height: 100px;
  }

  .select-row-margin {
    margin-top: 11px;
  }

  .header-inner {
    align-items: center;

    .header-inner-links {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #5f5f5f;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: -0.38px;
      line-height: 24px;
      cursor: pointer;

      @media (min-width: $lgBreak) {
        font-size: 18px;
      }

      .header-inner-link {
        border-bottom: 2px solid transparent;
        margin-right: 15px;

        @media (min-width: $lgBreak) {
          margin-right: 30px;
        }

        &.active {
          border-bottom: 2px solid $soCoPrimary;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .organisation-logo {
      max-height: 48px;

      @media (min-width: $smBreak) {
        max-height: 40px;
      }
    }

    .mobile-menu-icon {
      color: $soCoPrimary;
      font-size: 26px;
    }
  }
}
