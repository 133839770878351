@import '../../assets/css/variables';

#login {
  min-height: 100vh;

  @media (min-width: $smBreak) {
    height: 100vh;
  }

  &.container-fluid {
    padding: 0 !important;
  }

  .login-container {
    min-height: 100vh;
    padding-top: 7vh;

    @media (min-width: $smBreak) {
      height: 100vh;
      padding-top: 0;
      align-items: center;
    }

    .small-form-container {
      width: 400px;
      max-width: 100%;
      padding: 0 30px;
      margin: 0 auto;

      @media (min-width: $smBreak) {
        padding: 30px 45px;
        border: 1px solid #cdcdcd75;
        background-color: white;
        border-radius: 2px;
        margin-bottom: 30px;
      }

      .error {
        color: $soCoSecondary;
      }
    }
  }
}
