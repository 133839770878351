@import '../../assets/css/_variables.scss';

.radial-chart-container {
  position: relative;

  .radial-chart-score {
    color: #5f5f5f;
    font-size: 22px;
    line-height: 21px;
    position: absolute;
    top: 65%;
    left: 0;
    width: 100%;
    text-align: center;
    font-weight: bold;

    @media (min-width: $smBreak) {
      font-size: 25px;
      line-height: 25px;
    }

    .radial-chart-score-label {
      font-size: 18px;
      line-height: 21px;
      font-weight: normal;
      

      @media (min-width: $smBreak) {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}
