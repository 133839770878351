.gr-table {
  max-width: 100%;
  overflow-x: auto;
  border: 1px solid rgb(223, 227, 235);
  border-top-width: 0;

  .table-row {
    .row-item {
      display: flex;
      flex: 0 0 15%;
      padding: 5px 22px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      flex-grow: 0;
      flex-shrink: 0;
      overflow-wrap: break-word;
      align-items: center;
      border-top: 1px solid rgb(223, 227, 235);
      min-height: 50px;

      // Grow ONLY the last column so stretches to full table width
      &:last-child {
        flex-grow: 1;
      }

      &.row-header {
        background-color: white;
        color: rgb(51, 71, 91);
        text-align: left;
        text-transform: uppercase;
        align-items: center;
      }

      &.row-item-xs {
        flex-basis: 50px;
      }

      &.row-item-sm {
        flex-basis: 7.5%;
      }

      &.row-item-md {
        flex-basis: 15%;
      }

      &.row-item-lg {
        flex-basis: 20%;
      }
    }
  }
}