.table-row-partner-logo {
  height: 18px;
}

.transparent-logo-bg {
  background-color: #c1c0c0;
}

.gr-table .table-row .row-item.wrap {
  white-space: normal;
}

.gr-table .table-row .row-item .error {
  font-weight: normal;
}
