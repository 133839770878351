@import 'assets/css/_variables.scss';
@import 'assets/css/_helper.scss';
@import 'assets/fonts/light.css';
@import 'assets/fonts/fontawesome.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

#root {
  color: $black;
  background-color: #f7f7f7;
  min-height: 100vh;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;

  body.iframeMode & {
    .hideFromIframe {
      display: none !important;
    }
  }

  @media (min-width: $smBreak) {
    font-size: 14px;
  }

  input.form-control:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
  }

  #siteSummaryScreenshot {
    @media (min-width: $lgBreak) {
      min-height: 303px;
    }
  }
}

input.form-control:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
}

img {
  opacity: 1;
  transition: opacity 250ms;
  max-width: 100%;
}

img[data-src],
img.lazy {
  opacity: 0;
}

.lazy-box {
  min-width: 100px;
  min-height: 200px;

  @media (min-width: $smBreak) {
    min-width: 50px;
    min-height: 95px;
  }
}

.no-padding {
  padding: 0 !important;
}

.error {
  color: $soCoSecondary;
  font-weight: bold;
}

.standard-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}

.standard-shadow-clickable {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.07),
    0 3px 14px 3px rgba(0, 0, 0, 0.06), 0 4px 5px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.scrollable-tall {
  height: 75vh;
  overflow-y: scroll;
}

.pre-line {
  white-space: pre-line;
}

.underline {
  text-decoration: underline;
}

.input-group-append .btn {
  padding: 6px 10px;
}

.btn {
  cursor: pointer;
  border-radius: 0.1rem;
  padding: 8px 15px;

  &.btn-sm {
    padding: 6px 10px;
  }

  &.btn-xs {
    font-size: 12px;
    padding: 4px 8px;
  }

  &.btn-success {
    color: white;
  }

  &.btn-outline-success {
    &:hover {
      color: white;
    }
  }

  &-as-link {
    border: none;
    background: transparent;
    color: $soCoPrimary;

    &:hover {
      text-decoration: none;
      color: #1472c6;
    }
  }

  &.btn-outline-secondary {
    &:hover {
      color: white;
    }
  }

  &.no-border-radius {
    border-radius: 0;
  }

  &.mobile-wide {
    width: 100%;

    @media (min-width: $smBreak) {
      min-width: 120px;
      width: auto;
      max-width: 100%;
    }
  }

  &.wide {
    width: 100%;

    @media (min-width: $smBreak) {
      min-width: 120px;
      max-width: 100%;
    }
  }

  &.always-wide {
    width: 100%;
  }

  &.half-width {
    width: calc(50% - 5px);

    &.half-width-left {
      margin-right: 5px;
    }

    &.half-width-right {
      margin-left: 5px;
    }
  }

  &.active {
    color: white;
  }

  &:active {
    color: white;
  }
}

#inner-content {
  opacity: 0;
  transition: opacity 500ms linear;

  .white-box {
    padding: 20px 30px;
    background-color: white;
    border: 1px solid #d3d3d3;
    border-radius: 3px;
  }

  .faded-content {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vh;
    z-index: 8;
    background-color: #e1e1e180;

    @media (max-width: ($mdBreak - 1)) {
      &.visible {
        display: block;
      }
    }
  }
}

#header {
  display: flex;
  color: #313639;
  height: 40px;
  position: relative;
  box-shadow: none;
  background-color: white;
  padding: 5px 15px;
  align-items: center;

  .h5 {
    margin-bottom: 0;
  }
}

#app {
  display: flex;
  flex-wrap: wrap;

  #rightHandSide {
    flex: 1 1 100%;
    max-width: 100%;

    /* Required for lazy loading or will break */
    height: 100vh;
    overflow-y: auto;
    /* Required for lazy loading or will break */

    @media (min-width: $mdBreak) {
      flex: 1 1 calc(100% - 200px);
    }

    @media (min-width: $vlgBreak) {
      flex: 1 1 calc(100% - 260px);
    }

    .container-fluid {
      /* Needed for mobile, e.g. login where title at top edge */
      padding: 20px 15px;

      @media (min-width: ($lgBreak + 1)) {
        padding: 10px 20px;
        max-width: 1200px;
        margin: 0 auto;
      }
    }
  }
}

.large-spinner {
  color: $soCoTertiary;
  font-size: 50px;
  margin-bottom: 30px;

  @media (min-width: $lgBreak) {
    font-size: 66px;
    margin-bottom: 45px;
  }
}

h1,
.h1 {
  font-size: 32px;
  line-height: 40px;

  @media (min-width: ($lgBreak + 1)) {
    font-size: 32px;
    line-height: 42px;
  }
}

h2,
.h2 {
  font-size: 24px;
  line-height: 30px;

  @media (min-width: ($lgBreak + 1)) {
    font-size: 28px;
    line-height: 38px;
  }
}

h3,
.h3 {
  font-size: 18px;
  line-height: 24px;
  @media (min-width: $lgBreak) {
    font-size: 24px;
    line-height: 32px;
  }
}

h4,
.h4 {
  font-size: 18px;
  line-height: 24px;
  @media (min-width: $lgBreak) {
    font-size: 20px;
    line-height: 30px;
  }
}

h5,
.h5 {
  font-size: 16px;
  line-height: 20px;
}

select.form-control {
  width: auto;
}

/* Custom checkbox */
/* Customize the label (the container) */
.checkbox-container {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
  text-align: left;
  line-height: 24px;
  vertical-align: middle;
  padding-left: 35px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: white;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: #f3f3f3;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: #2196f3;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 9px;
    top: 5px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.search-input {
  max-width: 100%;
  width: 100%;

  @media (min-width: $smBreak) {
    width: 300px;
  }
}

input[type='text'].form-control,
input[type='password'].form-control,
input[type='url'].form-control,
label.form-control {
  max-width: 400px;

  &.bulky {
    color: #5f5f5f;
    border-radius: 0;
    min-height: 44px;
  }

  &.wide {
    max-width: 999px;
  }
}

#login,
#register,
#registerAgency {
  input[type='text'].form-control,
  input[type='password'].form-control,
  input[type='url'].form-control {
    max-width: 999px;
  }
}

.form-control,
.btn {
  font-size: 16px;

  @media (min-width: $smBreak) {
    font-size: 14px;
  }
}

.form-control:focus {
  box-shadow: none;
  border-color: $soCoTertiary;
}

.modal {
  // Need to re-state site CSS as Modal is not within #root, it is a top level element
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;

  @media (min-width: $smBreak) {
    font-size: 14px;
  }

  .modal-content {
    border-radius: 3px;
    border: none;
    /* fix for IE */
    display: block;
  }

  .modal-header {
    border-color: rgba(0, 0, 0, 0.1);
  }

  .scrollable-modal {
    max-height: 75vh;
    overflow-y: auto;
  }

  .modal-footer {
    border-color: rgba(0, 0, 0, 0.1);
  }
}

.loading-padded {
  margin-top: 30px;
  margin-bottom: 45px;
}

.loading-circle {
  position: relative;
  content: ' ';
  width: auto;
  height: auto;
  opacity: 1;
  visibility: visible;
  transition: all 0.7s ease-in-out;
  -webkit-transition: all 0.7s ease-in-out;

  .page-transition-layer-loader {
    position: relative;
    width: 100%;
    height: 5px;
    overflow: hidden;
    background-color: #f8f8f8;
  }

  .page-transition-layer-spinner {
    top: calc(50% - 33px);
    animation: PageTransitionSpinner 1.4s linear infinite;
  }

  @keyframes PageTransitionSpinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }

  .page-transition-layer-spinner .page-transition-layer-spinner-path {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center;
    stroke: $soCoTertiary;
    animation: PageTransitionSpinnerCircle 1.4s ease-in-out infinite;
  }

  @keyframes PageTransitionSpinnerCircle {
    0% {
      stroke-dashoffset: 187;
    }
    50% {
      stroke-dashoffset: 46.75;
      transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: 187;
      transform: rotate(450deg);
    }
  }
}

.uploadFileCustom {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.uploadFileCustom + label {
  padding: 8px 15px;
  font-weight: normal;
  font-size: 14px;
  color: white;
  background-color: $soCoTertiary;
  display: inline-block;
  text-align: center;
}

.uploadFileCustom:focus + label,
.uploadFileCustom + label:hover {
  background-color: darken($soCoTertiary, 5%);
}

.uploadFileCustom + label {
  cursor: pointer; /* "hand" cursor */
}

.uploadFileCustom:focus + label {
  outline: 1px dotted darken($soCoTertiary, 5%);
  outline: -webkit-focus-ring-color auto 5px;
}

.uploadFileCustom + label * {
  pointer-events: none;
}

.flex-row {
  display: flex;
  align-items: center;
  flex: 1 1 100%;
}

.large-loading-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.alert {
  background-color: white;
  border-radius: 3px;
  color: $black;

  &.alert-success {
    border-color: $soCoTertiary;
    border-top: 2px solid $soCoTertiary;

    .icon,
    .large-icon {
      color: $soCoTertiary;
    }
  }

  &.alert-danger {
    border-color: $soCoSecondary;
    border-top: 2px solid $soCoSecondary;

    .icon,
    .large-icon {
      color: $soCoSecondary;
    }
  }
}

.or-section {
  position: relative;
  display: block;
  width: 100%;
  height: 75px;
  padding: 20px 0;
  color: #cdcdcd;

  .or-section-text {
    position: absolute;
    z-index: 2;
    left: 135px;
    top: 24px;
    width: 32px;
    line-height: 24px;
    height: 26px;
    border: 1px solid #cdcdcd;
    color: #6f6f6f;
    border-radius: 20px;
    background-color: white;
    font-size: 12px;

    &.or-half-width {
      min-width: 30px;
      left: 45%;
      width: 10%;

      @media (min-width: $smBreak) {
        left: 47%;
        width: 6%;
      }
    }
  }
}

.login-logo-container {
  width: 50%;
  margin: 0 auto;

  @media (min-width: $smBreak) {
    width: 100%;
    margin: 0;
  }

  .login-logo {
    max-width: 100%;
    max-height: 40px;
    margin-bottom: 30px;
    margin-top: 30px;

    @media (min-width: $smBreak) {
      margin-top: 0;
    }
  }
}

.stretch-column {
  @media (min-width: $mdBreak) {
    flex-grow: 1;
    max-width: 100%;
  }
}

.short-column {
  @media (min-width: $mdBreak) {
    flex-grow: 0;
    max-width: 125px;
  }
}

.btn-google {
  background-color: #4285f4;
  color: white;
  height: 42px;
  line-height: 42px;
  display: flex;
  flex: 0 0 191px;
  max-width: 191px;
  flex-wrap: nowrap;
  font-weight: bold;
  border-radius: 2px;
  cursor: pointer;

  &.btn-google-center {
    margin: 0 auto;
  }

  &:hover,
  :focus {
    background-color: #3367d6;
    text-decoration: none;
  }

  .btn-google-logo {
    display: flex;
    flex: 0 0 40px;
    max-height: 100%;
  }

  .btn-google-text {
    font-size: 12px;
    display: flex;
    flex: 1 0 140px;
    text-align: center;
    justify-content: center;
    margin-right: 5px;

    @media (min-width: $mdBreak) {
      font-size: 13px;
    }

    &:hover,
    :focus {
      text-decoration: none;
    }
  }
}

.integration-logo {
  /* Do not use max-height */
  height: 21px;
  margin: 10px 0 8px;

  &.integration-logo-lg {
    height: 26px;
  }

  &.integration-logo-sm {
    height: 18px;
  }
}

.cta-button {
  width: 100%;

  @media (min-width: $lgBreak) {
    width: auto;
  }
}

.bg-gradient-primary {
  background: linear-gradient(87deg, #5e72e4, #825ee4) !important;
}

.bg-gradient-success {
  background: linear-gradient(87deg, #2dce89, #2dcea9) !important;
}

.bg-gradient-danger {
  background: linear-gradient(87deg, #f5365c, #f56036) !important;
}

.bg-gradient-light {
  background: linear-gradient(87deg, #f7fafc, #f7f8fc) !important;
  color: $black;

  .text-white {
    color: $black !important;
  }
}

.bg-gradient-dark {
  background: linear-gradient(87deg, #212529, #212229) !important;
}

.progress {
  height: 100%;
  border-radius: 0.2rem;
  background-color: #e9ecef;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);

  .progress-bar {
    height: 100%;
  }
}

.formInput {
  margin-bottom: 15px;
}

.gr-section {
  margin-bottom: 30px;

  @media (min-width: $mdBreak) {
    margin-bottom: 48px;
  }
}

.page-padding {
  @media (min-width: $lgBreak) {
    margin-left: 30px;
    max-width: calc(100% - 60px);
    flex: 0 0 calc(100% - 60px);
  }

  @media (min-width: $vlgBreak) {
    margin: 0 auto;
    max-width: 1400px;
    flex: 0 0 1400px;
  }

  .gr-section:last-child {
    margin-bottom: 10px;
  }
}

.navigation-row {
  margin-bottom: 15px;
}

#primaryScreenshot {
  border-radius: 6px;
  background-color: white;
}

.main-content-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1040px;
  }
}

.no-drop {
  cursor: no-drop;
}

a {
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.standard-page-layout {
  padding-top: 48px;
  background-color: #f9f9f9;
  margin: 0 0 30px 0;

  body.iframeMode & {
    background: transparent none;
  }
}

.standard-box {
  margin-bottom: 30px;
  flex-direction: column;
  display: flex;
  
  &.box-shadow {
    box-shadow: rgba(1, 26, 98, 0.1) -1px 1px 1px 0px;
  }

  @media (min-width: $mdBreak) {
    margin-bottom: 48px;
  }

  .standard-box-header {
    display: flex;
    padding: 0 30px;
    align-items: center;
    background-color: #e0e0e0;
    color: #5f5f5f;
    min-height: 45px;
    font-size: 16px;
    font-weight: bold;
    margin: 0;

    @media (min-width: $mdBreak) {
      min-height: 50px;
      font-size: 18px;
    }
  }

  .standard-box-content {
    background-color: #ffffff;
    min-height: 300px;
    padding: 30px;
    flex-grow: 1;

    @media (min-width: $mdBreak) {
      min-height: 320px;
    }

    &.standard-box-content-thin {
      padding: 30px 20px;
    }

    &.standard-box-content-no-padding {
      padding: 0;
    }

    &.standard-box-content-no-border {
      border: none;
    }

    &.standard-box-auto-height {
      @media (min-width: $mdBreak) {
        min-height: 0;
      }
    }
  }
}

.desktop-margin-top {
  @media (min-width: $mdBreak) {
    margin-top: 30px;
  }
}

.input-required {
  color: tomato;
}

input:disabled {
  cursor: not-allowed;
}

// overrides react-toastify styles
// if more required, import scss files instead
.Toastify__toast--success {
  background: $soCoTertiary !important;
}

.text-small {
  font-size: 12px;
  font-weight: 300;
}