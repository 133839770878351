@import '../../assets/css/_variables.scss';

#root #audit {
  .dashboard-box-content {
    padding: 30px;
  }

  .title {
    margin-top: 40px;

    @media screen and (min-width: $smBreak) {
      text-align: center;
    }
  }

  .description {
    margin-top: 0;
    text-align: center;
  }

  .hr {
    box-sizing: border-box;
    height: 1px;
    width: 100%;
    border-bottom: 1px solid #243d57;
    opacity: 0.25;
    margin: 30px 0;
  }

  .tab-menu {
    background-color: white;
    box-shadow: rgba(1, 26, 98, 0.1) -1px 0px 1px 0px;

    ul {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;

      li {
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        text-align: center;
        border-right: 1px solid #e0e0e0;
        &:last-child {
          border-right: none;
        }

        button {
          width: 100%;
          height: 100%;
          border-radius: 0;
          &.active {
            -webkit-box-shadow: 0 -5px 0 0 #3c98eb;
            box-shadow: 0 -5px 0 0 #3c98eb;
            color: inherit;
            font-weight: 700;
          }
        }
      }
    }
  }

  .issue-item {
    $padding: 30px;
    display: flex;
    border-bottom: 1px solid #243d5726;

    &:last-child {
      border-bottom: none;
    }

    @mixin passFail {
      display: block;
      color: white;
      font-size: 11px;
      line-height: 20px;
      height: 28px;
      width: 70px;
      padding: 4px 10px 4px 7px;
      text-align: center;
      // Cannot use absolute positioning on flex item for IE11
      margin-left: -36px;
      // Cannot use absolute positioning on flex item for IE11
      margin-right: -36px;
      border-radius: 5px 20px 20px 5px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
    }

    &-pass {
      @include passFail;
      background-color: $soCoTertiary;
    }

    &-fail {
      @include passFail;
      background-color: $soCoRed;
    }

    .issue-item-locator-table {
      table-layout: fixed;
      width: 100%;

      td,
      th {
        padding: 10px;
        border: 1px solid $grey;
      }

      a {
        // Force links full width for UX
        display: block;
        word-break: break-all;
      }

      p,
      li {
        &:only-child {
          margin: 0;
        }
      }
    }

    .issue-item-inner {
      // Flex column required for dynamic vertical height with vertical alignment in IE11
      display: flex;
      // Flex column required for dynamic vertical height with vertical alignment in IE11
      flex-direction: column;
      width: 100%;
    }

    .issue-item-difficulty {
      margin-right: 30px;
      font-weight: bold;

      .difficulty-score {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        color: $soCoTertiary;
        border: 1px solid;
        font-size: 11px;
        line-height: 20px;
        height: 28px;
        width: 70px;
        padding: 4px 10px;

        &.difficulty-medium {
          color: $orange;
        }

        &.difficulty-difficult {
          color: $soCoSecondary;
        }
      }
    }

    .issue-item-last-scan {
      margin: 0 30px 0 0;
    }

    .issue-item-footer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin: 20px 0;
    }

    .issue-item-header {
      min-height: 70px;
      display: flex;
      position: relative;
      align-items: center;
      cursor: pointer;
      margin: 0 $padding;
      user-select: none;

      .issue-item-header-title {
        flex: 1 0 50%;
        color: #5f5f5f;
        font-weight: bold;
        font-size: 16px;
        margin: 10px 0 10px 60px;

        @media (min-width: $lgBreak) {
          font-size: 18px;
        }
      }

      .issue-item-header-toggle {
        flex: 0 1 60px;
        font-size: 21px;
        justify-content: flex-end;
        display: flex;
        padding-right: 10px;
      }
    }

    .issue-item-info {
      margin: 0 $padding;
      padding-bottom: 10px;
    }

    .issue-item-details {
      display: flex;
      margin-bottom: 18px;
    }

    .issue-item-action {
      font-size: 1rem;
      font-weight: 600;
      color: #fff;
      background: $soCoPrimary linear-gradient(180deg, #59a7ee, $soCoPrimary) repeat-x;
      border-color: $soCoPrimary;
      border: 1px solid transparent;
      padding: 0.375rem 0.75rem;
      line-height: 1.5;
      border-radius: 0.25rem;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  
      &:hover {
        color: #fff;
        background: #1986e7 linear-gradient(180deg, $soCoPrimary, #1986e7) repeat-x;
        border-color: #177fdd;
      }

      &:not(:last-child) {
        margin-right: 18px;
      }
    }
  }
}

.learn-to-fix-modal {
  $scale0: (
    fontSize: 1rem,
    line: 1.5rem,
    autoLineCount: 1,
    autoLineHeight: 1.5rem,
  );
  $scale1: (
    fontSize: 1.2222222rem,
    line: 1.5rem,
    autoLineCount: 1,
    autoLineHeight: 1.5rem,
  );
  $scale2: (
    fontSize: 1.4444444rem,
    line: 1.5rem,
    autoLineCount: 1,
    autoLineHeight: 1.5rem,
  );
  $scale3: (
    fontSize: 1.7222222rem,
    line: 1.5rem,
    autoLineCount: 2,
    autoLineHeight: 3rem,
  );
  $scale4: (
    fontSize: 2.0555556rem,
    line: 1.5rem,
    autoLineCount: 2,
    autoLineHeight: 3rem,
  );

  p,
  li,
  pre {
    font-size: map-get($scale0, fontSize);
    line-height: map-get($scale0, autoLineHeight);
    margin-top: map-get($scale0, line);
    margin-bottom: map-get($scale0, line);

    @media screen and (max-width: $mdBreak - 1) {
      font-size: map-get($scale0, fontSize) * 0.875;
    }
  }

  a {
    text-decoration: underline;
  }

  h1 {
    font-size: map-get($scale4, fontSize);
    line-height: map-get($scale4, autoLineHeight);
    margin-top: map-get($scale4, line);
    margin-bottom: map-get($scale4, line);

    @media screen and (max-width: $mdBreak - 1) {
      font-size: map-get($scale4, fontSize) * 0.875;
    }
  }

  h2 {
    font-size: map-get($scale3, fontSize);
    line-height: map-get($scale3, autoLineHeight);
    margin-top: map-get($scale3, line);
    margin-bottom: map-get($scale3, line);

    @media screen and (max-width: $mdBreak - 1) {
      font-size: map-get($scale3, fontSize) * 0.875;
    }
  }

  h3 {
    font-size: map-get($scale2, fontSize);
    line-height: map-get($scale2, autoLineHeight);
    margin-top: map-get($scale2, line);
    margin-bottom: map-get($scale2, line);

    @media screen and (max-width: $mdBreak - 1) {
      font-size: map-get($scale2, fontSize) * 0.875;
    }
  }

  h4 {
    font-size: map-get($scale1, fontSize);
    line-height: map-get($scale1, autoLineHeight);
    margin-top: map-get($scale1, line);
    margin-bottom: map-get($scale1, line);

    @media screen and (max-width: $mdBreak - 1) {
      font-size: map-get($scale1, fontSize) * 0.875;
    }
  }

  > section:first-child {
    > h1:first-child {
      margin-top: 0.5rem;
    }
  }

  blockquote {
    padding: 0 1.5rem;

    p {
      margin: 0;
    }
  }

  .learn-to-fix-modal img {
    margin: 1.5rem 0;
  }
}
